import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import SocialProfile from '../../components/social-profile/social-profile';
import { StaticImage } from "gatsby-plugin-image"
import {
  IoLogoInstagram,
  IoLogoLinkedin,
  IoLogoGithub,
} from 'react-icons/io';
import {
  AboutWrapper,
  AboutImage,
  AboutPageTitle,
  AboutDetails,
  SocialProfiles,
} from './style';

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          about
          instagramProfile
          linkedinProfile
          githubProfile
      }
      }
    }
  `);

  const { author, instagramProfile, linkedinProfile, githubProfile} = Data.site.siteMetadata;

  const SocialLinks = [
    {
      icon: <IoLogoLinkedin />,
      url: `https://linkedin.com/in/${linkedinProfile}`,
      tooltip: `Linkedin: ${linkedinProfile}`,
    },
    {
      icon: <IoLogoGithub />,
      url: `https://github.com/${githubProfile}`,
      tooltip: `Github: ${githubProfile}`,
    },
    {
      icon: <IoLogoInstagram />,
      url: `https://instagram.com/${instagramProfile}`,
      tooltip: `Instagram: @${instagramProfile}`,
    }
  ];

  return (
    <AboutWrapper>
      <AboutPageTitle>
        <h2>Sobre {author}</h2>
        <p>
          StoryHub is a beautiful Gatsby Blog theme designed to showcase your
          work in style. Perfect for designers, artists, photographers and
          developers to use for their portfolio website.
        </p>
      </AboutPageTitle>

      <AboutImage>
        <StaticImage src='../../images/about.jpg' alt="author" />
      </AboutImage>

      <AboutDetails>
        <h2>Hey there, what’s up?</h2>
        <p>
          RedQ Team is a creative agency specializing in building scalable,
          high-performance web & mobile application. Our main concern is
          creating more value into the application so that can help our
          customers to grow their business.
        </p>
        <p>
          RedQ Team is a creative agency specializing in building scalable,
          high-performance web & mobile application. Our main concern is
          creating more value into the application so that can help our
          customers to grow their business.
        </p>

        <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles>
      </AboutDetails>
    </AboutWrapper>
  );
};

export default About;
